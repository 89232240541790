<template>
	<v-main class="pagePg">
		<v-fade-transition mode="out-in">
			<router-view />
		</v-fade-transition>
	</v-main>
</template>

<script>
export default {
	name: "BaseView",
};
</script>

<style>
.theme--light .pagePg {
	background: #eeeeee;
}



/* ================== 美化滚动条样式 ================ */
/*
::-webkit-scrollbar {
	width: 4px;
	height: 4px;
	background-color: white;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	visibility: hidden;
}

::-webkit-scrollbar-thumb:vertical {
	-webkit-border-radius: 2px;
	border-radius: 2px;
	background-color: darkgrey;
	background-image: linear-gradient(transparent, white, transparent);
}

::-webkit-scrollbar-thumb:horizontal {
	-webkit-border-radius: 2px;
	border-radius: 2px;
	background-color: darkgrey;
	background-image: linear-gradient(90deg, transparent, white, transparent);
}

::-webkit-scrollbar:window-inactive {
	visibility: hidden;
}

::-webkit-scrollbar-thumb:window-inactive {
	visibility: hidden;
}
*/
</style>