<template>
	<v-app-bar
		app
		elevate-on-scroll
		id="home-app-bar"
		style="padding: 0 61.5px"
	>
		<v-img
			:src="require('@/assets/images/sfc_logo_landscape.png')"
			contain
			max-width="110"
		/>
		<v-spacer></v-spacer>

		<v-tabs background-color="transparent" centered>
			<v-tab
				v-for="(name, i) in items"
				:key="i"
				:exact="name === 'Home'"
				:to="{ name }"
			>
				{{ $t(name) }}
			</v-tab>
		</v-tabs>

		<v-btn
			class="mr-2"
			color="primary"
			small
			text
			href="https://scoring.sdchina.org.cn"
			target="_blank"
			disabled
		>
			<v-icon left> mdi-login </v-icon>
			{{ $t("teamLogin") }}
		</v-btn>

		<!-- <v-btn
			class="mr-2"
			color="primary"
			small
			text
			@click="changeLanguage"
			disabled
		>
			<v-icon left>mdi-translate</v-icon>
			{{ $t("lang") }}
		</v-btn> -->

		<v-icon class="mr-2">mdi-theme-light-dark</v-icon>
		<v-switch
			:value="$vuetify.theme.dark"
			hide-details
			label=""
			@change="changeTheme"
		></v-switch>
	</v-app-bar>
</template>

<style>
#home-app-bar .v-tabs-slider {
	max-width: 24px;
	margin: 0 auto;
}
</style>


<script>
export default {
	name: "HomeAppBar",

	data: () => ({
		drawer: null,
		items: [
			"home",
			"news",
		],
	}),

	methods: {
		changeLanguage() {
			if (this.$i18n.locale === "en") {
				this.$i18n.locale = "cn";
			} else {
				this.$i18n.locale = "en";
			}
			console.log("changeLanguage", this.$i18n.locale);
		},
		changeTheme() {
			this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
			console.log("changeTheme", this.$vuetify.theme.dark);
		},
	},
};
</script>


