<template>
	<v-app>
		<home-app-bar />

		<home-view />

		<home-footer />

		<!-- <home-settings /> -->
	</v-app>
</template>

<script>
import HomeAppBar from "@/layouts/home/AppBar";
import HomeFooter from "@/layouts/home/Footer";
import HomeView from "@/layouts/home/View";
import HomeSettings from "@/layouts/home/Settings";

export default {
	name: "HomeLayout",

	components: {
		HomeAppBar,
		HomeFooter,
		HomeView,
		HomeSettings,
	},
};
</script>